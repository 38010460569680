import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Home from '@/views/Home.vue'
import ErrorPage from "@/components/ErrorPage";
import FondsdetailsRedirect from "../views/FondsdetailsRedirect.vue";

import middlewareClients from './middleware/clients'
import Registration from "../views/Registration";
import RemoteLogin from "../views/RemoteLogin";

import store from "@/store";

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/:client/',
    name: 'Home', 
    component: () => import('../views/Fondslist.vue')
  },
  {
    path: '/:client/fondsliste',
    name: 'fondslist', 
    component: () => import('../views/Fondslist.vue')
  },
  {
    path: '/:client/fondsdetails/:id',
    name: 'fondsdetails',
    component: () => import('../views/Fondsdetails.vue')
  },
  {
    path: '/:client/kaeufermail',
    name: 'kaeufermail',
    component: () => import('../views/Kaeufermail.vue')
  },
  {
    path: '/:client/auftragsliste',
    name: 'auftragsliste',
    component: () => import('../views/Auftragslist.vue')
  },
  {
    path: '/:client/auftragsdetails/:id',
    name: 'auftragsdetails',
    component: () => import('../views/Auftragsdetails.vue')
  },  
  {
    path: '/:client/vermittlungsliste',
    name: 'vermittlungsliste',
    component: () => import('../views/Vermittlungslist.vue')
  },
  {
    path: '/:client/vermittlungsdetails/:id',
    name: 'vermittlungsdetails',
    component: () => import('../views/Vermittlungsdetails.vue')
  },
  {
    path: '/:client/auftragsverwaltung',
    name: 'AuftragsVerwaltung',
    component: () => import('../views/AuftragsVerwaltung.vue')
  },
  {
    path: '/:client/auftragsverwaltungthanks',
    name: 'AuftragsVerwaltungThanks',
    component: () => import('../views/AuftragsVerwaltungThanks.vue')
  },
  {
    path: '/:client/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:client/remotelogin',
    name: 'remotelogin',
    component: RemoteLogin
  },  
  {
    path: '/:client/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/:client/changepassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/:client/resetpassword',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/:client/setpassword',
    name: 'SetPassword',
    component: () => import('../views/SetPassword.vue')
  },
  {
    path: '/:client/setpasswordupdate',
    name: 'SetPasswordUpdate',
    component: () => import('../views/SetPasswordUpdate.vue')
  },  
  {
    path: '/:client/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/:client/registerconfirm',
    name: 'ConfirmRegistration',
    component: () => import('../views/ConfirmRegistration.vue')
  },
  {
    path: '/:client/newsletterconfirm',
    name: 'ConfirmNewsletterRegistration',
    component: () => import('../views/ConfirmNewsletterRegistration.vue')
  },  
  {
    path: '/:client/loginconfirm',
    name: 'ConfirmLoginCreation',
    component: () => import('../views/ConfirmLoginCreation.vue')
  },  
  {
    path: '/:client/userprofile',
    name: 'UserProfile',
    component: () => import('../views/UserProfile.vue')
  },
  {
    path: '/:client/infoservices',
    name: 'Infoservices',
    component: () => import('../views/Infoservices.vue')
  },
  {
    path: '/:client/compliance',
    name: 'Compliance',
    component: () => import('../views/Compliance.vue')
  },
  {
    path: '/:client/compliancedownloads',
    name: 'ComplianceDownloads',
    component: () => import('../views/ComplianceDownloads.vue')
  },
  {
    path: '/:client/viewlimit',
    name: 'ViewLimitReached',
    component: () => import('../views/ViewLimitReached.vue')
  },
  {
    path: '/:client/compliancelimit',
    name: 'ComplianceLimit',
    component: () => import('../views/ComplianceLimit.vue')
  },
  {
    path: '/:client/styles',
    name: 'Demo',
    component: Home
  },
  {
    path: '/:client/servicestatus',
    name: 'BackendServiceStatus',
    component: () => import('../views/BackendServiceStatus.vue')
  },
  {
    path: '/:client/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue')
  },
  {
    path: '/:client/wi/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue')
  },    
  // Legacy support
  {
    path: '/:client/wi/fondsdetails/',
    name: 'fondsdetailsredirect',
    component: FondsdetailsRedirect
  }, 
  {
    path: '/:client/fondsdetails/',
    name: 'fondsdetailsredirect',
    component: FondsdetailsRedirect
  },  
  {
    path: '/:client/fondsdetailsredirect/',
    name: 'fondsdetailsredirect',
    component: FondsdetailsRedirect
  },
  {
    path: '/:client/index',
    redirect: '/:client/',
  },
  {
    path: '/:client/index.html',
    redirect: '/:client/',
  },
  {
    path: '/:client/start',
    redirect: '/:client/',
  },
  {
    path: '/:client/start.xml',
    redirect: '/:client/',
  },
  {
    path: '/:client/wi/index',
    redirect: '/:client/',
  },
  {
    path: '/:client/wi/index.html',
    redirect: '/:client/',
  },
  {
    path: '/:client/wi/start',
    redirect: '/:client/',
  },
  {
    path: '/:client/wi/start.xml',
    redirect: '/:client/',
  },
  //  
  {
    path: '/:client/wrongsubsite',
    name: 'Wrong subsite',
    component: ErrorPage,
    props: { errorType: 'subsite' }
  },
  {
    path: '/error/:errorType',
    name: 'ErrorClientNotFound',
    component: ErrorPage,
    props: true
  },
  {
    path: '/:client/*',
    name: 'Error', 
    // Server-Whitelisting?: change this to component: () => import('../views/Provoke404.vue') and the server answers with HTTP404 and basic HTML
    component: ErrorPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

function combineBeforeHooks(...hooks) {
  return function (to, from, next) {
    // Rufe jede Middleware-Funktion auf
    hooks.forEach(hook => {
      hook(to, from, next);
    });
  };
}

const combinedMiddleware = combineBeforeHooks(
    middlewareClients,
    (to, from, next) => {
      const hasToken = store.getters['auth/hasToken'];
      const isGoingToCompliance = to.path.includes('/compliance');
      const isGoingToLogout = to.path.includes('/logout');

      if (hasToken && store.getters["compliance/hasConfidentiality"] === false && !isGoingToCompliance && !isGoingToLogout) {
        next('/compliance');
      } else {
        next();
      }

      if (from.name === 'ComplianceDownloads' && to.name === 'fondsdetails') {
        // Hier wird die Payload des Dokumenten-Downloads aus dem Getter abgerufen und der Download gestartet,
        // wenn man von ComplianceDownloads kommend auf die fondsdetails navigiert

        const payload = {
          filename: store.getters["complianceDownloads/downloadPayload"].filename,
          href: store.getters["complianceDownloads/downloadPayload"].href,
          subsite: store.getters["complianceDownloads/downloadPayload"].subsite,
        }

        store.dispatch('complianceDownloads/deleteDownloadPayload');

        store.dispatch('downloadFileWithToken', payload);

        next();
      }
    },
);

router.beforeEach(combinedMiddleware);

export default router
